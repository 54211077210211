import lottie from 'lottie-web';
import KoalafiAnimationLoader from './loader.json';
import modalShortHtml from './modal-html';
import modalCss from './modal-css';

type openModalArgs = {
  orderId: string;
  pdi: string;
  useStage: boolean;
  launchPage: launchPageTypes;
  hybridToken: string;
};

export enum launchPageTypes {
  checkout = 'checkout',
  financing = 'financing',
  product = 'product',
}

const insertModalCss = (cssStyleTagId: string, css: string) => {
  const cssStyleTagExists = !!document.getElementById(cssStyleTagId);
  // only add style script if it doesn't exist
  if (!cssStyleTagExists) {
    const style = document.createElement('style');
    style.id = cssStyleTagId;
    style.innerHTML = css;
    document.getElementsByTagName('head')[0].appendChild(style);
  }
};

const generateKfiHTML = (ecommUrl: string, options: Partial<openModalArgs>) => {
  let queryParams: string;
  let launchPageType: launchPageTypes;

  launchPageType = launchPageTypes.checkout;
  if (options.launchPage) {
    switch (options.launchPage) {
      case 'product':
        launchPageType = launchPageTypes.product;
        break;
      case 'financing':
        launchPageType = launchPageTypes.financing;
        break;
      default:
        //default to highest perm page
        launchPageType = launchPageTypes.checkout;
    }
  }

  queryParams = `?`;

  if (options.orderId) {
    queryParams += `orderId=${options.orderId}`;
  } else {
    throw new Error(`openModal(): invalid args`);
  }
  if (options.pdi) {
    queryParams += `&pdi=${options.pdi}`;
  }
  if (options.launchPage) {
    queryParams += `&launchPage=${launchPageType}`;
  }
  if (options.useStage) {
    queryParams += `&stage`;
  }
  if (options.hybridToken) {
    queryParams += `&hybridToken=${options.hybridToken}`;
  }

  return modalShortHtml(ecommUrl, queryParams);
};

const insertModalHtml = (modifiedHtml: string, emptyDivId: string, modalContentId: string) => {
  const emptyDivExists = !!document.getElementById(emptyDivId);
  if (!emptyDivExists) {
    const emptyDiv = document.createElement('div');
    emptyDiv.id = emptyDivId;
    document.body.appendChild(emptyDiv);
  }

  // Prevents scrolling behind the modal
  document.body.style.overflow = 'hidden';
  document.getElementById(emptyDivId).insertAdjacentHTML('afterend', modifiedHtml);

  // Enables the animation of the modal appearing. We need to wait for the
  // modal background to appear briefly so the animation of the modal will work
  setTimeout(function () {
    console.log(modalContentId, 'modalcontentid');
    document.getElementById(modalContentId).classList.add('show');
  }, 4);
};

const handleCloseModalStyling = (modalElement: HTMLElement, modalContentId: string) => {
  document.getElementById(modalContentId).classList.remove('show');
  // Enables animation to take effect
  setTimeout(function () {
    modalElement.style.display = 'none';
    // Removing the main container as well
    const modalContainer = document.querySelectorAll('#koalafi-modal');
    if (modalContainer.length > 0) {
      for (let i = 0; i < modalContainer.length; i++) {
        modalContainer[i].remove();
      }
    }
  }, 200);
  document.body.style.overflow = 'visible';

  const event = new CustomEvent('closeModal', { detail: { isModalClosed: true } });
  window.dispatchEvent(event);
};

// keeping backwards compatibility
const openModal = async (options: openModalArgs | string) => {
  let orderId: string;
  let publicDealerId: string;
  let useStage: boolean;
  let launchPage: launchPageTypes;
  let hybridToken: string;

  if (typeof options === 'object') {
    ({ orderId, pdi: publicDealerId, useStage, launchPage: launchPage, hybridToken } = options);
  } else if (typeof options === 'string') {
    // this is backwards compatible to ensure BoT passing orderId as a string still works.
    orderId = options;
  }

  if (!orderId && !publicDealerId) {
    throw new Error('openModal(): arguments not passed in correctly.');
  }

  const EMPTY_DIV_ID = 'koalafi-div';
  const CSS_STYLE_TAG_ID = 'koalafi-css';
  const ECOMM_URL = process.env.ECOMM_URL;
  const MODAL_ID = `koalafi-modal`;
  const MODAL_CONTENT_ID = `koalafi-modal-content`;
  const CLOSE_BUTTON_ID = `koalafi-modal-close-button`;
  const IFRAME_ID = `koalafi-iframe`;

  const modifiedHtml = generateKfiHTML(ECOMM_URL, {
    orderId,
    pdi: publicDealerId,
    useStage,
    launchPage: launchPage,
    hybridToken,
  });

  let isDoneLoading = false;

  try {
    insertModalCss(CSS_STYLE_TAG_ID, modalCss);
    insertModalHtml(modifiedHtml, EMPTY_DIV_ID, MODAL_CONTENT_ID);
    const animation = lottie.loadAnimation({
      container: document.querySelector('#lottie-loader'),
      animationData: KoalafiAnimationLoader,
    });

    animation.addEventListener('loopComplete', () => {
      if (isDoneLoading) {
        // Taking into account the possiblity there could be multiple open
        const loadingContainers = document.querySelectorAll('.koalafi-loader');

        if (loadingContainers.length > 0) {
          for (let i = 0; i < loadingContainers.length; i++) {
            loadingContainers[i].remove();
          }
        }
        animation.destroy();
      }
    });
  } catch (err) {
    console.error('Inserting CSS/HTML: ', err);
  }

  const wcModal = document.getElementById(MODAL_ID);
  const closeModalButton = document.getElementById(CLOSE_BUTTON_ID);

  // Placeholder lets that can be used to resolve/reject returned Promise
  let promiseResolve;
  let promiseReject;

  document.onclick = function (event) {
    if (event.target == closeModalButton) {
      // Notifies our app that modal is closing
      const iframe = document.getElementById(IFRAME_ID) as HTMLIFrameElement;
      // App is expecting this exact message upon modal closure
      iframe?.contentWindow?.postMessage('Closed modal with X button', ECOMM_URL);

      handleCloseModalStyling(wcModal, MODAL_CONTENT_ID);

      const payload = {
        orderId: orderId,
        reason: 'closed modal with X button',
      };
      promiseReject(payload);
    }
  };

  window.addEventListener(
    'message',
    function (event: MessageEvent) {
      // Prevents reading events from unintended sites.
      if (event.origin !== ECOMM_URL) return;

      const eventData = JSON.parse(event.data);

      if (eventData.wcfSuccess) {
        promiseResolve(eventData.payload);
      } else if (eventData.wcfSuccess === false) {
        promiseReject(eventData.payload);
      }

      if (eventData.closeModal) {
        handleCloseModalStyling(wcModal, MODAL_CONTENT_ID);
      }

      if (eventData.stopSpinner) {
        isDoneLoading = true;
      }
      if (eventData.startSpinner) {
        const loaderContainer = document.getElementById('koalafi-loader');
        isDoneLoading = false;
        // if loader is already showing, don't show it again
        if (loaderContainer.style.display !== 'none') {
          return;
        }
        loaderContainer.style.display = 'flex';

        const animation = lottie.loadAnimation({
          container: document.querySelector('#lottie-loader'),
          animationData: KoalafiAnimationLoader,
        });

        animation.addEventListener('loopComplete', () => {
          if (isDoneLoading) {
            const loadingContainers = document.querySelectorAll('.koalafi-loader');
            if (loadingContainers.length > 0) {
              for (let i = 0; i < loadingContainers.length; i++) {
                loadingContainers[i].remove();
              }
            }
            animation.destroy();
          }
        });
      }
    },
    false,
  );

  return new Promise((resolve, reject) => {
    // For code readability: eventListener and onclick were moved outside of return
    // statement, since they are added to the window/document and aren't scoped to promise
    promiseResolve = resolve;
    promiseReject = reject;
  });
};

export { openModal, generateKfiHTML };
