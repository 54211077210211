const modalCss = `#koalafi-modal {
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
  }
  
  #koalafi-iframe,
  #koalafi-modal-content {
    flex-direction: row;
    justify-content: center;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
  }
  
  #koalafi-loader {
    z-index: 10002;
    background-color: white;
    border-radius: 0.3rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  #lottie-loader {
    position: absolute;
    bottom: 60%;
    width: 65%;
    aspect-ratio: 1.36 / 1;
  }
  
  @media screen and (min-width: 480px) {
    #koalafi-loader {
      margin: 90px auto 48px;
      max-width: 425px;
      height: 810px;
      width: 640px;
    }
  }`;

export default modalCss;
