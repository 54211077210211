const modalShortHtml = (kfiUrl: string, queryParams: string) => {
  return `<div id="koalafi-modal">
    <div id="koalafi-modal-content">
      <div id="koalafi-loader" class="koalafi-loader">
        <div id="lottie-loader"></div>
      </div>
      <div id="koalafi-iframe-wrapper">
        <iframe id="koalafi-iframe" frameborder="0" src="${kfiUrl}${queryParams}"></iframe>
      </div>
    </div>
  </div>`;
};

export default modalShortHtml;
