import { gql, GraphQLClient } from 'graphql-request';
import { Order, NewOrder } from '../gql/graphql';

interface iedgeService {
  fetchOrder(orderId: string): Promise<Order>;
  createOrder(publicDealerId: string, orderInput: NewOrder): Promise<Order>;
}

let graphQLClient: GraphQLClient;

const createOrderMutation = gql`
  mutation createOrder($orderInput: NewOrder!) {
    createOrder(input: $orderInput) {
      orderId
      orderErrors {
        __typename
        ... on FatalError {
          message
        }
        ... on BadRequest {
          message
        }
      }
    }
  }
`;

const fetchOrderQuery = gql`
  query order($id: ID!) {
    order(id: $id) {
      metadata {
        formType
      }
    }
  }
`;

const edgeService: iedgeService = {
  fetchOrder: async (id: string): Promise<Order> => {
    if (!graphQLClient) {
      graphQLClient = new GraphQLClient(process.env.GRAPH_QL_ENDPOINT);
    }

    return graphQLClient.request(fetchOrderQuery, { id });
  },
  createOrder: async (publicDealerId: string, orderInput: NewOrder): Promise<Order> => {
    if (!graphQLClient) {
      graphQLClient = new GraphQLClient(process.env.GRAPH_QL_ENDPOINT);
    }
    graphQLClient.setHeader('public-dealer-id', publicDealerId);
    return graphQLClient.request(createOrderMutation, { orderInput });
  },
};

export { edgeService };
